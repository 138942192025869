<template>
  <div>
    <div class="logo-horizontal">
      <router-link to="/"
        ><img src="@/assets/horizontal.png" alt=""
      /></router-link>
    </div>
    <div v-if="!regdone" class="container">
      <h1>Regisztráció</h1>
      <div>
        <h2 class="label">Felhasználónév</h2>
        <b-form-input
          :state="nameState"
          v-model="name"
          maxlength="30"
          placeholder="Válassz egy felhasználónevet..."
        ></b-form-input>

        <b-form-invalid-feedback id="input-live-feedback">
          {{ nameStateText }}
        </b-form-invalid-feedback>
      </div>

      <div>
        <h2 class="label">E-mail cím</h2>
        <b-form-input
          :state="emailState"
          v-model="email"
          maxlength="100"
          placeholder="Írd be az email címed..."
        ></b-form-input>

        <b-form-invalid-feedback id="input-live-feedback">
          {{ emailStateText }}
        </b-form-invalid-feedback>
      </div>

      <div>
        <h2 class="label">Jelszó</h2>
        <div class="flexi">
          <b-form-input
            :state="passwordState"
            :type="passwordType"
            v-model="password"
            maxlength="30"
            placeholder="Adj meg egy jelszót..."
            class="password-input"
          ></b-form-input>
          <div
            v-if="passwordType == 'password'"
            @click="passwordTypeShow(true)"
            class="eye"
            v-b-tooltip.hover
            title="Megmutat"
          >
            <b-icon icon="eye"></b-icon>
          </div>
          <div
            v-else
            @click="passwordTypeShow(false)"
            class="eye"
            v-b-tooltip.hover
            title="Elrejt"
          >
            <b-icon icon="eye-slash"></b-icon>
          </div>
          <b-form-invalid-feedback id="input-live-feedback">
            A jelszó minimum 8, maximum 30 karakter hosszú lehet és nem
            tartalmazhat speciális karaktereket!
          </b-form-invalid-feedback>
        </div>
      </div>

      <div>
        <h2 class="label">Jelszó újra</h2>
        <div class="flexi">
          <b-form-input
            :state="password2State"
            :type="password2Type"
            v-model="password2"
            maxlength="30"
            placeholder="Add meg újra a jelszót..."
            class="password-input"
          ></b-form-input>
          <div
            v-if="password2Type == 'password'"
            @click="password2TypeShow(true)"
            class="eye"
            v-b-tooltip.hover
            title="Megmutat"
          >
            <b-icon icon="eye"></b-icon>
          </div>
          <div
            v-else
            @click="password2TypeShow(false)"
            class="eye"
            v-b-tooltip.hover
            title="Elrejt"
          >
            <b-icon icon="eye-slash"></b-icon>
          </div>
          <b-form-invalid-feedback id="input-live-feedback">
            A két jelszónak meg kell egyezni!
          </b-form-invalid-feedback>
        </div>
      </div>

      <h2 class="label"></h2>
      <b-form-checkbox
        id="checkbox-1"
        v-model="checkboxStatus"
        name="checkbox-1"
        value="true"
        unchecked-value=""
      >
        Elfogadom a
        <router-link to="felhasznalasi">Felhasználási feltételeket</router-link>
      </b-form-checkbox>

      <h2 class="label"></h2>
      <b-button @click="regClick" variant="primary">Regisztrálok</b-button>
    </div>
    <div v-if="regdone" class="container">
      <div>
        <h4>Sikeres regisztráció!</h4>
        <p>
          Elküldtünk a <b>{{ email }}</b> címedre egy emailt. A benne található
          linkre kattintava érvényesítheted a regisztrációdat. Amennyiben az
          email nem érkezik meg 10 percen belül, nézd meg a levélszemét/spam
          mappában is. Ha továbbra sem találod, küldj el egy tetszőleges emailt
          a regisztrált emailfiókodból az emailvalidation@elektrogeek.hu címre
          és a lehető leggyorsabban érvényesítjük a regisztrációdat.
        </p>
        <p>További szép napot!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { emailValidation, specialChar } from "../util/validation";
import Api from "../util/api";

export default {
  name: "registration",
  data() {
    return {
      name: "",
      nameExist: false,
      nameState: null,
      nameStateText:
        "A felhasználónév minimum 3, maximum 30 karakter hosszú lehet és nem tartalmazhat speciális karaktereket!",
      email: "",
      emailState: null,
      emailStateText: "Érvénytelen email cím!",
      httpResponseEmail: "",
      password: "",
      password2: "",
      passwordType: "password",
      password2Type: "password",
      checkboxStatus: "",
      regdone: false,
    };
  },
  watch: {
    email() {
      if (this.email.length != 0) {
        if (
          emailValidation(this.email) &&
          this.email != this.httpResponseEmail
        ) {
          let self = this;
          Api.checkEmail(this.email)
            .then(function (response) {
              console.log(response.data);
              if (response.data) {
                self.emailState = false;
                self.emailStateText =
                  "Ezzel az email címmel már regisztráltak!";
              } else {
                self.emailState = true;
                self.emailStateText = "Érvénytelen email cím!";
              }
            })
            .catch((err) => console.log(err));
        } else {
          this.emailState = false;
          self.emailStateText = "Érvénytelen email cím!";
        }
      } else {
        this.emailState = null;
        self.emailStateText = "Érvénytelen email cím!";
      }
    },
    name() {
      if (this.name.length == 0) {
        this.nameState = null;
      } else if (this.name.length == 1 || this.name.length == 2 || !specialChar(this.name)) {
        this.nameState = false;
        this.nameStateText =
          "A felhasználónév minimum 3, maximum 30 karakter hosszú lehet és nem tartalmazhat speciális karaktereket!";
      } else if (this.name.length > 2) {
        let self = this;
        Api.checkName(this.name)
          .then(function (response) {
            if (response.data) {
              self.nameState = false;
              self.nameStateText = "A felhasználónév foglalt!";
            } else {
              self.nameState = true;
            }
          })
          .catch((err) => console.log(err));
      }
    },
  },
  methods: {
    passwordTypeShow(type) {
      if (type) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    password2TypeShow(type) {
      if (type) {
        this.password2Type = "text";
      } else {
        this.password2Type = "password";
      }
    },
    regClick() {
      if (
        this.emailState &&
        this.passwordState &&
        this.password2State &&
        this.checkboxStatus
      ) {
        this.$loading(true);
        let self = this;
        Api.registration(this.name, this.email, this.password)
          .then(function (response) {
            self.$loading(false);
            console.log(response.data);
            if (response.data.fault == "false") {
              self.regdone = true;
              console.log("1.pont");
            }
            else if (response.data.fault == "true") {
              self.makeToast(
                "Hiba!",
                "danger",
                "Váratlan hiba történt! Próbáld később újra!"
              );
            }
            else if (response.data.fault == "block") {
              self.makeToast(
                "Hiba!",
                "danger",
                "24 órán belül maximum 3 regisztráció engedélyezett, így egy napot várnod kell!"
              );
            }
          })
          .catch((err) => {
            self.$loading(false);
            console.log(err);
          });
      } else {
        this.makeToast(
          "Hiba!",
          "danger",
          "Minden mezőt helyesen ki kell tölteni és el kell fogadni a felhasználási feltételeket!"
        );
      }
    },
    makeToast(title, variant, errorMessage) {
      this.$bvToast.toast(errorMessage, {
        title: title,
        autoHideDelay: 5000,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    httpResponse() {
      if (this.email == this.httpResponseEmail && this.email != "") {
        return true;
      } else {
        return false;
      }
    },
    passwordState() {
      if (this.password.length != 0) {
        if (specialChar(this.password) && this.password.length > 7) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    password2State() {
      if (this.password2.length != 0) {
        if (this.password2 == this.password) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
p {
  text-align: justify;
}
.flexi {
  display: flex;
  flex-wrap: wrap;
}
.password-input {
  width: 90%;
}
.eye {
  line-height: 2.4rem;
  margin-left: 10px;
  cursor: pointer;
}
#input-live-feedback {
  width: 100%;
}
.logo-horizontal {
  width: 260px;
  margin: 0 auto;
  margin-top: 20px;
}
.container {
  position: relative;
  max-width: 700px;
  margin: 10px auto;
  padding: 35px 35px 60px 35px;
  color: #444;
  background: white;
}
h1 {
  text-align: center;
}
h2 {
  margin-top: 30px;
  color: #777;
}
h3 {
  text-align: center;
  margin-top: 50px;
}
h4 {
  text-align: center;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  padding: 45px;
}
.loading {
  text-align: center;
  margin-top: 30px;
}
.red {
  color: rgb(220, 53, 69);
}
p {
  margin-top: 45px;
}
</style>